.overlay {
	display: flex;
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background: rgba(255, 255, 255, 0.6);
	justify-content: center;
	align-items: center;
	animation: show-overlay 0.6s;
	z-index: 30;
}

@keyframes show-overlay {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}