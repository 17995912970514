:root {
	touch-action: manipulation;
}


h1 {
	font-size: 2.2em !important;
	font-weight: bold !important;
}
h2 {
	margin-top: 0.25em !important;
}

.liedje {
	background: white;
	color: grey;

	.artist {
		color: black;
	}

	.title {
		color: black;
		font-weight: bold;
	}

	.btn {
		margin-left: 8px;
	}
}

.logo-container {
	text-align: center;

	.logo {
		display: inline-block;
		max-width: 100%;
		max-height: 25vh;
	}
}

.avatar {
	font-size: 2em;
	line-height: 1rem;
	margin-left: -0.25em;
	margin-right: 0.1em;

	&:empty {
		display: none;
	}
}